<template>
  <div class="forgetMarginPwd">
    <div class="forgetMarginPwdTitle">
      <div class="myForgetMarginPwd">
        <p>忘记资金密码 <span class="tip">&nbsp;&nbsp;&nbsp;&nbsp;温馨提示：密码修改后10分钟后生效</span></p>
        <!-- <p class="tip"></p> -->
      </div>
    </div>
    <div class="forgetMarginPwdContent">
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <el-form-item label="手机号" prop="mobileNo">
          <el-input v-model="form.mobileNo"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="validNo" class="validFormItem">
          <el-input v-model="form.validNo"></el-input>
          <el-button
            class="validButton"
            :disabled="validBtnDisabled"
            @click="sendValidNo()"
          >{{ validButtonText }}</el-button>
        </el-form-item>
        <el-form-item label="新密码" prop="marginPwd">
          <el-input v-model="form.marginPwd" :type="newPwdText">
              <img slot="suffix" :src="closeEye" v-if="passNewType" @click="showPwd1" />
                  <img slot="suffix"  :src="openEye" v-else  @click="hidePwd1" />
          </el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="reMarginPwd">
          <el-input v-model="form.reMarginPwd" :type="newAginPwdText">
              <img slot="suffix" :src="closeEye" v-if="newPwdType" @click="showPwd2" />
                  <img slot="suffix"  :src="openEye" v-else  @click="hidePwd2" />
          </el-input>
        </el-form-item>
        <div class="submitButton">
          <el-button type="danger" @click="submitApply()">提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import md5 from "js-md5";
export default {
  data() {
    const validatePassCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.form.marginPwd) {
        callback(new Error("确认密码和新密码不一致"));
      } else {
        callback();
      }
    };
    return {
      newPwdText:'password',
      newAginPwdText:'password',
      passNewType:true,
      newPwdType:true,
      closeEye:require('../../../assets/images/closeeye.png'),
      openEye:require('../../../assets/images/openeye.png'),
      validButtonText: "发送验证码",
      validBtnDisabled: false,
      form: {
        mobileNo: "",
        validNo: "",
        marginPwd: "",
        reMarginPwd: ""
      },
      rules: {
        mobileNo: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "请输入11位手机号码",
            trigger: "blur"
          }
        ],
        validNo: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        marginPwd: [
          { required: true, message: "请输入新资金密码", trigger: "blur" }
        ],
        reMarginPwd: [
          { required: true, message: "请输入确认资金密码", trigger: "blur" },
          { validator: validatePassCheck, trigger: "blur" }
        ]
      }
    };
  },
  methods: {
     showPwd1(){
      this.passNewType = false
      this.newPwdText = 'text'
    },
    hidePwd1(){
      this.passNewType= true
      this.newPwdText = 'password'
    },
     showPwd2(){
      this.newPwdType = false
      this.newAginPwdText = 'text'
    },
    hidePwd2(){
      this.newPwdType= true
      this.newAginPwdText = 'password'
    },
    setTime(e, vueThis) {
      let _this = this;
      e--;
      vueThis.validButtonText = e + "s";
      if (e > 0) {
        setTimeout(function() {
          _this.setTime(e, vueThis);
        }, 1000);
      } else {
        vueThis.validButtonText = "发送验证码";
        vueThis.validBtnDisabled = false;
      }
    },
    sendValidNo() {
      this.$refs["form"].validateField("mobileNo", valid => {
        if (valid == "") {
          protocol.param_authn_authn_sendCaptcha.param.loginAccount = this.form.mobileNo;
          protocol.param_authn_authn_sendCaptcha.param.mark = "SMS_177245473";
          protocol.param_authn_authn_sendCaptcha.param.id = 27;
          http
            .postFront(protocol.param_authn_authn_sendCaptcha)
            .then(response => {
              this.$EL_MESSAGE(response.data.message);
              const that = this;
              this.validBtnDisabled = true;
              this.setTime(60, that);
            });
        }
      });
    },
    submitApply() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocolFwd.param_resetMarginPwd.param.phone = this.form.mobileNo;
          protocolFwd.param_resetMarginPwd.param.code = this.form.validNo;
          protocolFwd.param_resetMarginPwd.param.newPassWord = md5('47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=' + this.form.marginPwd);
          http.postFront(protocolFwd.param_resetMarginPwd).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.$refs['form'].resetFields();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        }
      });
    }
  }
};
</script>
<style scoped>
.forgetMarginPwd {
  padding: 0 15px;
}
.forgetMarginPwd .myForgetMarginPwd {
  float: left;
  line-height: 40px;
}
.forgetMarginPwdTitle {
  overflow: hidden;
}
.forgetMarginPwdTitle .myForgetMarginPwd p {
  font-size: 16px;
}
.forgetMarginPwdTitle .myForgetMarginPwd .tip {
  font-size: 18px!important;
  color:red;
  font-weight: 700;
}
.forgetMarginPwdContent {
  width: 80%;
}
.validFormItem {
  position: relative;
}
.validFormItem .validButton {
  position: absolute;
  width: 20%;
  top: 0;
  right: 0;
}
</style>
